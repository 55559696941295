(function () {
  'use strict';

  angular
    .module('form')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('form', {
        url: '/form/:requestId?location',
		params:{
			location:{value:'btn', squash:true}
		},
        views:{
			navigation: {
			   templateUrl: 'navigation/navigation.tpl.html',
			   controller: 'NavigationCtrl',
			   controllerAs: 'navigation'
			},
			alerts: {
			   templateUrl: 'alerts/alerts.tpl.html',
			   controller: 'AlertsCtrl',
			   controllerAs: 'alerts'
			},
			main:{
				templateUrl: 'form/form.tpl.html',
				controller: 'FormCtrl',
				controllerAs: 'form'
			},
			aside:{
				templateUrl: 'messages/messages.tpl.html',
				controller: 'MessagesCtrl',
				controllerAs: 'messages'
			},
			footer: {
			   templateUrl: 'footer/footer.tpl.html',
			   controller: 'FooterCtrl',
			   controllerAs: 'footer'
			 }
		}
      });
  }
}());
